/** Capitlizes the first character of a string */

export function capitalizeFirst(text: string) {
  if (!text.length) return text;
  return text[0].toUpperCase() + text.slice(1);
}

/** Eliminates text from a string which is enclosed by a starting and ending delimiter */

export function eliminateParenthesized(input: string, start: string, end: string): string {
  let res = '';
  let level = 0;
  let last: number | undefined;
  for (let i = 0; i < input.length; i++)
    if (input[i] === start) {
      if (last !== undefined) {
        res = res.concat(input.substring(last, i));
        last = undefined;
      }
      level++;
    } else if (input[i] === end) {
      if (last !== undefined) {
        res = res.concat(input.substring(last, i));
        last = undefined;
      }
      level = Math.max(0, level - 1);
    } else if (!level && last === undefined) last = i;
  if (last !== undefined) res = res.concat(input.substring(last));
  return res;
}

/** Checks if a character code is alphanumeric */

export function isAlphaNumeric(code: number) {
  return (code > 47 && code < 58) || (code > 64 && code < 91) || (code > 96 && code < 123);
}

/** Splits a text at all non-alphanumeric characters */

export function splitNonAlphaNumeric(input: string): string[] {
  const res: string[] = [];
  let last: number | undefined;
  for (let i = 0; i < input.length; i++)
    if (isAlphaNumeric(input.charCodeAt(i))) {
      if (last === undefined) last = i;
    } else if (last !== undefined) {
      res.push(input.substring(last, i));
      last = undefined;
    }
  if (last !== undefined) res.push(input.substring(last));
  return res;
}

/**
 * Compares two strings while ignoring the letter case and leading/trailing whitespace.
 * @param str1 The first string to compare.
 * @param str2 The second string to compare.
 * @returns A boolean indicating whether the strings are the same after ignoring case and leading/trailing whitespace.
 */
export function compareStrings(str1: string, str2: string): boolean {
  // Remove leading/trailing whitespace and convert both strings to lowercase
  const cleanedStr1 = str1.trim().toLowerCase();
  const cleanedStr2 = str2.trim().toLowerCase();

  // Compare the cleaned strings
  return cleanedStr1 === cleanedStr2;
}

/**
 * Checks if a string contains any substring from the provided whitelist,
 * ignoring the case of both the string and whitelist entries.
 * @param str The string to check.
 * @param whitelist An array of strings to compare against.
 * @returns A boolean indicating whether the string contains any substring from the whitelist.
 */
export function stringInWhitelist(str: string, whitelist: string[]): boolean {
  return whitelist.some((w) => str.toLowerCase().includes(w));
}

/** Replaces all occurences of a character in a string */
export function replaceChar(str: string, match: string, repl: string) {
  return str.split(match).join(repl);
}
