import { FC, useMemo } from 'react';
import { CaveSeverity } from '@sqior/viewmodels/patient';
import { Icons } from '@sqior/viewmodels/visual';
import { useTheme } from '@sqior/react/theme';
import { SIcon } from '@sqior/react/uibase';

type IconProps = {
  icon: Icons;
  color: string;
};

interface Props {
  severity?: CaveSeverity;
  size?: number;
}

export const CaveIcon: FC<Props> = ({ severity, size }) => {
  const {
    theme: {
      colors: {
        primary: {
          sqiorBlue: { value: sqiorBlue },
          warning: { value: warning },
          emergency: { value: emergency },
        },
      },
    },
  } = useTheme();

  const caveSeverityIconMap = useMemo(
    () =>
      new Map<CaveSeverity, IconProps>([
        [CaveSeverity.Info, { icon: Icons.Cave, color: sqiorBlue }],
        [CaveSeverity.Warning, { icon: Icons.Cave, color: warning }],
        [CaveSeverity.Critical, { icon: Icons.Cave, color: emergency }],
      ]),
    []
  );

  const color = severity ? caveSeverityIconMap.get(severity)?.color : undefined;

  if (!severity) return null;
  return <SIcon icon={Icons.Cave} color={color} size={size} />;
};

export default CaveIcon;
