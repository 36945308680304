import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';

export const ExperienceUrlPath = 'experience-url-path';

export const ExperienceBasePath = 'experience';
export enum ExperienceSubPaths {
  Setup = 'setup',
}

export enum ExperienceState {
  Request = 'request',
  Confirm = 'confirm',
  Step3 = '3',
  Step4 = '4',
}

export enum ExperienceSetup {
  Restart = 'Restart',
  ConfirmBed = 'ConfirmBed',
  PrepareTransport = 'PrepareTransport',
}

export type SetupExperienceType = { experience: string };
export function SetupExperience(experience: ExperienceSetup): OperationSpec<SetupExperienceType> {
  return {
    type: OperationType.Add,
    path: joinPath(ExperienceBasePath, ExperienceSubPaths.Setup),
    data: { experience },
  };
}

export enum VideoStates {
  BeforeBedRequest = 'before_bed_request',
  Occupying = 'occupying',
  Closing = 'closing',
}
