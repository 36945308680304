import { Icon } from '@iconify/react';
import { Icons } from '@sqior/viewmodels/visual';
import { cloneElement, CSSProperties, ReactElement } from 'react';
import { ReactComponent as Cancel } from './svg-icons/cancel.svg';
import { ReactComponent as ChangeDescription } from './svg-icons/change-description.svg';
import { ReactComponent as ChangeLocation } from './svg-icons/change-location.svg';
import { ReactComponent as Inserted } from './svg-icons/inserted.svg';
import { ReactComponent as Reordered } from './svg-icons/reordered.svg';
import { ReactComponent as EventBusy } from './svg-icons/event-busy.svg';
import { ReactComponent as Lock } from './svg-icons/lock.svg';
import { ReactComponent as Report } from './svg-icons/report.svg';
import { ReactComponent as EventAvailable } from './svg-icons/event_available.svg';
import { ReactComponent as EventUpcoming } from './svg-icons/event_upcoming.svg';
import { ReactComponent as PatientPositioning } from './svg-icons/patient_positioning.svg';
import { ReactComponent as Team } from './svg-icons/team.svg';
import { ReactComponent as Checklist } from './svg-icons/checklist.svg';
import { ReactComponent as Surgical } from './svg-icons/surgical.svg';
import { ReactComponent as GpsLocation } from './svg-icons/location.svg';
import { ReactComponent as Spi } from './svg-icons/spi.svg';
import { ReactComponent as Barcode } from './svg-icons/barcode.svg';
import { ReactComponent as Description } from './svg-icons/description.svg';
import { ReactComponent as Anesthesia } from './svg-icons/anesthesia.svg';
import { ReactComponent as Insurance } from './svg-icons/insurance.svg';
import { ReactComponent as Male } from './svg-icons/male.svg';
import { ReactComponent as Female } from './svg-icons/female.svg';
import { ReactComponent as Unisex } from './svg-icons/unisex.svg';
import { ReactComponent as Work } from './svg-icons/work.svg';
import { ReactComponent as Case } from './svg-icons/case.svg';
import { ReactComponent as PremedicationChecked } from './svg-icons/premedication_checked.svg';
import { ReactComponent as PremedicationMissing } from './svg-icons/premedication_missing.svg';
import { ReactComponent as PremedicationNotApproved } from './svg-icons/premedication_notApproved.svg';
import { ReactComponent as Download } from './svg-icons/download.svg';
import { ReactComponent as Visibility } from './svg-icons/visibility.svg';
import { ReactComponent as VisibilityOff } from './svg-icons/visibility_off.svg';
import { ReactComponent as ForwardArrow } from './svg-icons/arrow-forward.svg';
import { ReactComponent as BackArrow } from './svg-icons/arrow-back.svg';
import { ReactComponent as Calendar } from './svg-icons/calendar.svg';
import { ReactComponent as LocalAnesthesia } from './svg-icons/local-anesthesia.svg';
import { ReactComponent as Diagnosis } from './svg-icons/diagnosis.svg';
import { ReactComponent as Search } from './svg-icons/search.svg';
import { ReactComponent as Close } from './svg-icons/close.svg';
import { ReactComponent as TransDone } from './svg-icons/trans-done.svg';
import { ReactComponent as Cave } from './svg-icons/cave.svg';
import { ReactComponent as Tv } from './svg-icons/tv.svg';

const NO_ICON = <Icon icon="octicon:question-24" fontSize={30} />;
const DEFAULT_SIZE = 24;

const defaultStyle: CSSProperties = {
  width: DEFAULT_SIZE,
  height: DEFAULT_SIZE,
};

export const stringToJSXMap = {
  [Icons.Bed]: NO_ICON,
  [Icons.Calender]: <Calendar />,
  [Icons.Chat]: NO_ICON,
  [Icons.Check]: NO_ICON,
  [Icons.Clock]: NO_ICON,
  [Icons.Diagnosis]: <Diagnosis />,
  [Icons.Escalation]: NO_ICON,
  [Icons.Transport]: NO_ICON,
  [Icons.Oneway]: NO_ICON,
  [Icons.Cancel]: <Cancel />,
  [Icons.ChangeDescription]: <ChangeDescription />,
  [Icons.ChangeLocation]: <ChangeLocation />,
  [Icons.Inserted]: <Inserted />,
  [Icons.Reordered]: <Reordered />,
  [Icons.EventBusy]: <EventBusy />,
  [Icons.Lock]: <Lock />,
  [Icons.Report]: <Report />,
  [Icons.EventAvailable]: <EventAvailable />,
  [Icons.EventUpcoming]: <EventUpcoming />,
  [Icons.PatientPositioning]: <PatientPositioning />,
  [Icons.Team]: <Team />,
  [Icons.CheckList]: <Checklist />,
  [Icons.Barcode]: <Barcode />,
  [Icons.Spi]: <Spi />,
  [Icons.ORLocation]: <Surgical />,
  [Icons.Location]: <GpsLocation />,
  [Icons.Description]: <Description style={defaultStyle} />,
  [Icons.Anesthesia]: <Anesthesia />,
  [Icons.Insurance]: <Insurance />,
  [Icons.Male]: <Male />,
  [Icons.Female]: <Female />,
  [Icons.Unisex]: <Unisex />,
  [Icons.Work]: <Work />,
  [Icons.Case]: <Case />,
  [Icons.PremedicationChecked]: <PremedicationChecked />,
  [Icons.PremedicationMissing]: <PremedicationMissing />,
  [Icons.PremedicationNotApproved]: <PremedicationNotApproved />,
  [Icons.Download]: <Download />,
  [Icons.Visibility]: <Visibility />,
  [Icons.VisibilityOff]: <VisibilityOff />,
  [Icons.BackArrow]: <BackArrow />,
  [Icons.ForwardArrow]: <ForwardArrow />,
  [Icons.LocalAnesthesia]: <LocalAnesthesia />,
  [Icons.Search]: <Search />,
  [Icons.Close]: <Close />,
  [Icons.TransportDone]: <TransDone />,
  [Icons.Cave]: <Cave />,
  [Icons.Tv]: <Tv />,
} as const;

type StringToJSXMap = typeof stringToJSXMap;
export type StringToJSXMapKeys = keyof StringToJSXMap;

export interface SIconProps extends CSSProperties {
  icon?: Icons | StringToJSXMapKeys;
  size?: number;
  color?: string;
  alternative?: ReactElement;
  className?: string;
}

export function SIcon({
  icon,
  size,
  color = 'white',
  alternative,
  className,
  ...rest
}: SIconProps) {
  if (!icon || !stringToJSXMap[icon]) return alternative ? alternative : NO_ICON;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {cloneElement(stringToJSXMap[icon], {
        width: size ? size : DEFAULT_SIZE,
        height: size ? size : DEFAULT_SIZE,
        color: color,
        fill: color,
        className,
        ...rest,
      })}
    </div>
  );
}

export default SIcon;
