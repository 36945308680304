import { ItemState } from '@sqior/viewmodels/visual';
import { TypographyColor } from './typography/typography';
import { DarkTheme } from '@sqior/react/theme';

export const StateTypographyMap = new Map<ItemState, TypographyColor>([
  [ItemState.Normal, TypographyColor.DEFAULT],
  [ItemState.Highlighted, TypographyColor.PRIMARY],
  [ItemState.Hot, TypographyColor.HOT],
  [ItemState.Challenged, TypographyColor.WARNING],
  [ItemState.Greyed, TypographyColor.SECONDARY],
  [ItemState.Emergency, TypographyColor.EMERGENCY],
]);

export const StateColorMap = new Map<ItemState, string>([
  [ItemState.Normal, DarkTheme.colors.text.primary.value],
  [ItemState.Highlighted, DarkTheme.colors.primary.sqiorBlue.value],
  [ItemState.Hot, DarkTheme.colors.primary.femalePink.value],
  [ItemState.Challenged, DarkTheme.colors.primary.warning.value],
  [ItemState.Greyed, DarkTheme.colors.text.secondary.value],
  [ItemState.Emergency, DarkTheme.colors.primary.emergency.value],
]);
