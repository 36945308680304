import styles from './specialty-view.module.css';
import React, { CSSProperties, FC } from 'react';
import { styled } from '@mui/material/styles';
import { tooltipClasses, TooltipProps } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { SpecialtyVM } from '@sqior/viewmodels/patient';
import { SvgAccountBalance } from '../svg-icon/svg-icon';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { useTheme } from '@sqior/react/theme';

export interface SpecialtyViewProps extends BaseSpecialtyViewProps {
  variant?: 'default' | 'long';
  textZIndex?: number;
}
const renderSpecialtyView = (
  specialtyitem: SpecialtyVM,
  style?: React.CSSProperties,
  className?: string,
  fontSize?: number,
  fullWidth?: boolean,
  uppercase?: boolean
) => {
  return (
    <BaseSpecialtyView
      specialty={specialtyitem}
      style={style}
      className={className}
      fontSize={fontSize}
      fullWidth={fullWidth}
      uppercase={uppercase}
    />
  );
};

export const SpecialtyView: FC<SpecialtyViewProps> = ({
  specialty,
  quotapecialty,
  style,
  className,
  fontSize,
  fullWidth,
  uppercase,
  variant,
  textZIndex,
}) => {
  if (quotapecialty) {
    return (
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', justifyContent: 'center' }}>
        {quotapecialty.map((spec) =>
          renderSpecialtyView(spec, style, className, fontSize, fullWidth, uppercase)
        )}
      </div>
    );
  }

  if (specialty) {
    const Base = (
      <BaseSpecialtyView
        specialty={specialty}
        style={style}
        className={className}
        fontSize={fontSize}
        fullWidth={fullWidth}
        uppercase={uppercase}
        textZIndex={textZIndex}
      />
    );

    if (!variant || variant === 'default') return Base;

    return (
      <div className={styles['container']}>
        <SvgAccountBalance />
        <div>{specialty.specialtyLong}</div>
        {Base}
      </div>
    );
  }

  return null;
};

export default SpecialtyView;

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#3a506b',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3a506b',
  },
}));

interface BaseSpecialtyViewProps {
  specialty?: SpecialtyVM;
  quotapecialty?: SpecialtyVM[];
  style?: CSSProperties;
  className?: string;
  fontSize?: number;
  fullWidth?: boolean;
  uppercase?: boolean;
  textZIndex?: number;
}

const BaseSpecialtyView: FC<BaseSpecialtyViewProps> = ({
  specialty,
  style,
  className,
  fontSize,
  fullWidth,
  uppercase,
  textZIndex,
}) => {
  const { getContentColor } = useTheme();

  if (!specialty) return null;

  const { fg, bg } = getContentColor(specialty.specialtyColor as ColorCodes);

  return (
    <BootstrapTooltip title={specialty.specialtyLong}>
      <div
        className={styles['specialty-container']}
        style={{
          fontSize: fontSize || 9,
          width: fullWidth ? '100%' : undefined,
          padding: '2px 4px',
          borderRadius: 2,
          backgroundColor: bg.value,
          // border: `1px solid ${border.value}`,
          ...style,
        }}
      >
        <div
          className={styles['specialty-content']}
          style={{
            textTransform: uppercase ? 'uppercase' : undefined,
            color: fg.value,
            zIndex: textZIndex,
          }}
        >
          {specialty.specialty}
        </div>
      </div>
    </BootstrapTooltip>
  );
};
