import { Entity, EntityHeader } from '@sqior/js/entity';
import { ColorCodes } from '@sqior/viewmodels/visual';
import { AnalyticViewModelTypes } from './card-types';

export enum CardSize {
  S = 's',
  M = 'm',
  L = 'l',
}
export type Card = Entity & {
  hGrow: number;
};

export function addSize(entity: Entity | undefined, hGrow: number): Card | undefined {
  return entity ? { ...entity, hGrow } : undefined;
}

export type AnalyticDashboardItems = AnalyticDashboardItem[];
export type AnalyticDashboardItem = Card | AnalyticDashboardItems;

export type AnalyticsDashboardVM = EntityHeader & {
  items: AnalyticDashboardItems[];
};

export type AnalyticsDashboardGroupVM = AnalyticsDashboardVM & {
  title: string;
  color?: ColorCodes;
  hGrow: number;
};
export function makeAnalyticsDashboardGroup(
  items: (AnalyticDashboardItem | undefined)[],
  title: string,
  hGrow: number,
  color?: ColorCodes
): AnalyticsDashboardGroupVM {
  return {
    entityType: AnalyticViewModelTypes.DashboardGroup,
    items: [items.filter(Boolean) as AnalyticDashboardItem[]],
    title,
    color,
    hGrow,
  };
}
