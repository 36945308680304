import { FC } from 'react';
import { PersonSex } from '@sqior/viewmodels/person';
import { SIcon } from '@sqior/react/uibase';
import { Icons, ItemState } from '@sqior/viewmodels/visual';
import { StateColorMap } from '../ui-maps';

interface Props {
  sex?: PersonSex | string;
  size?: number;
  state?: ItemState;
}

const sexIconMap = new Map<PersonSex, Icons>([
  [PersonSex.Female, Icons.Female],
  [PersonSex.Male, Icons.Male],
  [PersonSex.Diverse, Icons.Unisex],
]);

export const SexIcon: FC<Props> = ({ sex, size, state }) => {
  const icon = sexIconMap.get(sex as PersonSex);
  const color = state ? StateColorMap.get(state) : undefined;
  return <SIcon icon={icon} size={size} color={color} />;
};

export default SexIcon;
