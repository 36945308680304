import {
  HighlightButton,
  InputControl,
  InterweaveExt,
  TitlePage,
  useTextResources,
} from '@sqior/react/uibase';
import styles from './phone-number-entry-page.module.css';
import { ChangeEvent, useContext, useState } from 'react';
import { OperationContext } from '@sqior/react/operation';
import { EnterPhoneNumber, ClosePhoneNumber } from '@sqior/viewmodels/device';
import { ZIndex } from '@sqior/react/utils';

/* eslint-disable-next-line */
export interface PhoneNumberEntryPageProps {}

export function PhoneNumberEntryPage(props: PhoneNumberEntryPageProps) {
  const dispatcher = useContext(OperationContext);
  const textDict = useTextResources();
  const [number, setNumber] = useState('');
  return (
    <TitlePage
      title={textDict.get('enter_phone_number')}
      titleSize="20pt"
      onClose={() => {
        dispatcher.start(ClosePhoneNumber());
      }}
      zIndex={ZIndex.PhoneNumberEntryPage}
    >
      <div className={styles['container']}>
        <InterweaveExt content={textDict.get('enter_phone_number_description')} />
        <div className={styles['input-container']}>
          <InputControl
            className={styles['input']}
            value={number}
            autoFocus={true}
            placeholder={textDict.get('enter_phone_number_placeholder')}
            type="tel"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setNumber(e.target.value);
            }}
            confirm={() => {
              if (number.length) dispatcher.start(EnterPhoneNumber(number));
            }}
          />
        </div>
        <div className={styles['button-container']}>
          <HighlightButton
            disabled={!number.length}
            onClick={() => {
              dispatcher.start(EnterPhoneNumber(number));
            }}
          >
            {textDict.get('confirm')}
          </HighlightButton>
        </div>
      </div>
    </TitlePage>
  );
}

export default PhoneNumberEntryPage;
