import styles from './blocker-list-view.module.css';
import { SIcon } from '@sqior/react/uibase';
import { Icons } from '@sqior/viewmodels/visual';
import Typography from '../typography/typography';

export type BlockerListViewProps = {
  text: string;
  time: string;
};

export function BlockerListView({ text, time }: BlockerListViewProps) {
  return (
    <div className={styles['container']}>
      <div className={styles['icon']}>
        <SIcon icon={Icons.Lock} size={28} />
      </div>
      <div className={styles['textcontainer']}>
      <Typography type="p">{time}</Typography>
      <Typography type="p">{text}</Typography>
      </div>
    </div>
  );
}

export default BlockerListView;
