import styles from './orworkflow-list-item.module.css';
import { FactoryProps } from '@sqior/react/factory';
import { OperationContext } from '@sqior/react/operation';
import { InterweaveExt } from '@sqior/react/uibase';
import { memo, useContext } from 'react';
import { ORWorkflowCountdownType, ORWorkflowListItemBase } from '@sqior/viewmodels/orworkflow';
import { ItemState } from '@sqior/viewmodels/visual';
import ORWorkflowIndicatorControl from '../orworkflow-indicator-control/orworkflow-indicator-control';
import { useSearchMatch } from '@sqior/react/hooks';
import { useUIGlobalState } from '@sqior/react/state';
import { motion } from 'framer-motion';
import { CaveIcon, Name, SexIcon, Typography, TypographyColor } from '@sqior/react/sqior-ui';
import { format } from 'date-fns';

export type ORWorkflowListItemProps = FactoryProps<ORWorkflowListItemBase>;

export const ORWorkflowListItem = memo(({ data, onClose, statePath }: ORWorkflowListItemProps) => {
  const {
    emergencyClassification,
    prioGroup,
    patientState,
    patient,
    firstName,
    lastName,
    dob,
    sex,
    description,
    diagnosis,
    select,
    step,
    indicator,
    location,
    caveWorstSeverity,
    searchKeys,
  } = data;

  const {
    UIGlobalState: { searchQuery },
  } = useUIGlobalState();
  const selected = useSearchMatch({ searchKeys, searchQuery });

  const dispatcher = useContext(OperationContext);
  const emergencyCategory = emergencyClassification?.category;

  const onClick = () => {
    if (select) dispatcher.start(select);
  };

  const getOpacity = () => {
    if (searchQuery.length === 0) {
      return 1;
    } else if (selected) {
      return 1;
    } else {
      return 0.5;
    }
  };

  const getBackgroundColor = () => {
    if (typeof indicator !== 'object') return undefined;
    switch (indicator.countdownType) {
      case ORWorkflowCountdownType.Surgery:
        return '#122C44';
      case ORWorkflowCountdownType.Anesthesia:
        return '#2E2645';
      default:
        return undefined;
    }
  };

  const backgroundColor = getBackgroundColor();
  return (
    <motion.div
      whileTap={{ scale: 0.95 }}
      layout="position"
      className={styles['main-container']}
      animate={{
        borderColor: selected ? '#1cade4' : 'rgba(0, 0, 0, 0)',
        opacity: getOpacity(),
      }}
      style={{
        borderRadius: '10px',
        border: '1px solid rgba(0, 0, 0, 0)',
        backgroundColor,
        cursor: 'pointer',
      }}
      onMouseDown={onClick}
    >
      <div className={styles['container']}>
        <div className={styles['patient-container']}>
          {emergencyCategory && (
            <span
              className={styles['emergency']}
              style={{
                fontSize: 18,
              }}
            >
              <b>{emergencyCategory.toUpperCase()}</b>
            </span>
          )}
          {prioGroup && (
            <span
              className={styles[prioGroup.state === ItemState.Challenged ? 'challenge' : 'normal']}
              style={{
                fontSize: 18,
              }}
            >
              {prioGroup.label}
            </span>
          )}

          <Name firstName={firstName} lastName={lastName} state={patientState} fontSize={18} />
          <SexIcon sex={sex} state={patientState} size={20} />
          <CaveIcon severity={caveWorstSeverity} size={18} />
          {dob && (
            <Typography secondary color={TypographyColor.GRAY}>{`*${format(
              dob,
              'd.M.yyyy'
            )}`}</Typography>
          )}
        </div>

        {step && <InterweaveExt content={step} />}
        {diagnosis && <div>{diagnosis}</div>}
        {description && <div>{description}</div>}
        {location && <InterweaveExt content={location} />}
      </div>
      {indicator && (
        <div className={styles['icon-state']}>
          <ORWorkflowIndicatorControl
            indicator={indicator}
            countdownBackgroundColor={backgroundColor}
          />
        </div>
      )}
    </motion.div>
  );
});

export default ORWorkflowListItem;
