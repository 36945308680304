import { joinUrlPath } from './url-utils';

export interface IConfigContext {
  getBaseUrl(): URL;

  getEndpoint(key: string): URL;
  getWSEndpoint(key: string): URL;
}

/** Simple implementation of IConfigContext
 *
 * If a baseUrl is specified in the constructor
 *  ==> this base url is used always.
 *
 * If the baseUrl is not specified in the constructor
 *  => the baseUrl will be derived from the browser window.location
 */
export class SimpleConfigContext implements IConfigContext {
  constructor(baseUrl?: URL) {
    if (baseUrl !== undefined) this.baseUrl = baseUrl;
    else this.baseUrl = new URL(window.location.href);
  }

  /** Returns the base url
   *
   */
  getBaseUrl(): URL {
    return this.baseUrl;
  }

  /** Returns endpoint URL relativ to the baseUrl i.e. baseUrl + key
   *
   */
  getEndpoint(key: string): URL {
    const pathname = this.baseUrl.pathname;

    if (key.startsWith('/')) return new URL(key, this.getBaseUrl());

    return new URL(joinUrlPath(pathname, key), this.getBaseUrl());
  }

  /** Returns an websocket endpoint URL i.e., prefixed with ws or wss + baseUrl + key*/
  getWSEndpoint(key: string): URL {
    const port = this.baseUrl.port;
    const protocol = this.baseUrl.protocol;
    const hostname = this.baseUrl.hostname;
    const pathname = this.baseUrl.pathname;

    const wsProtocol = protocol === 'https:' ? 'wss:' : 'ws:';
    const wsPort = port;

    let wsUrl: URL;
    if (key.startsWith('/')) wsUrl = new URL(key, wsProtocol + '//' + hostname + ':' + wsPort);
    else wsUrl = new URL(joinUrlPath(pathname, key), wsProtocol + '//' + hostname + ':' + wsPort);

    return wsUrl;
  }

  private readonly baseUrl: URL;
}
