import { OperationSpec, OperationType } from '@sqior/js/operation';

export const DefaultSpecialtiesPath = 'default-specialties';

/** Type of state */
export type DefaultSpecialtiesData = {
  specialties: { id: string; text: string }[];
  selected?: string;
};

export function SetDefaultSpecialty(key: string): OperationSpec<string> {
  return { type: OperationType.Add, path: DefaultSpecialtiesPath, data: key };
}
